import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import LongContentDisplay from '~/components/LongContentDisplay'
import { Avatar } from '~/core/ui/Avatar'
import { Container } from '~/core/ui/Container'
import {
  TypographyH1,
  TypographyH2,
  TypographyH3,
  TypographyH4
} from '~/core/ui/Heading'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import {
  CareerPageSettingType,
  TenantType
} from '~/lib/features/careers/[id]/types'
import CareerList, { CareerSimpleJobCountId } from './CareerList'

const SimpleCareersView: React.FC<{
  careerPageSetting: CareerPageSettingType
  tenant?: TenantType
}> = ({ careerPageSetting, tenant }) => {
  const { t, i18n } = useTranslation()
  const { query } = useRouter()

  const handleScrollToJobList = useCallback(() => {
    const JobListElement = document.querySelector(`#${CareerSimpleJobCountId}`)

    if (JobListElement) {
      JobListElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return (
    <Container
      overrideClass={`h-full min-h-[calc(100vh_-_73px)] max-w-[1440px] bg-[url('/img/feature/careers/banner_hero_mobile.png')] tablet:bg-[url('/img/feature/careers/banner_hero_desktop.png')] bg-no-repeat bg-contain`}>
      <div className="m-auto max-w-[804px] px-4 pb-12 pt-8 tablet:px-0 tablet:pb-[139px] tablet:pt-[92px]">
        <div className="mb-4 flex justify-center tablet:mb-5">
          <Avatar
            className="hidden tablet:flex"
            size="3xl"
            shape="rounded"
            defaultAvatar={false}
            src={tenant?.logoVariants?.thumb?.url}
          />
          <Avatar
            className="tablet:hidden"
            size="lg"
            shape="rounded"
            defaultAvatar={false}
            src={tenant?.logoVariants?.thumb?.url}
          />
        </div>
        <TypographyH1 className="mb-6 hidden text-center font-semibold text-gray-900 dark:text-gray-300 tablet:block">
          {careerPageSetting.page_title}
        </TypographyH1>

        <TypographyH3 className="mb-6 text-center text-gray-900 dark:text-gray-300 tablet:hidden">
          {t('careers:discoverNextMove')} <br />
          <span className="text-uppercase">{tenant?.name}</span>
        </TypographyH3>
        <div className="mb-8 text-center tablet:mb-[140px]">
          {tenant?.openJobsCount && tenant?.openJobsCount > 0 ? (
            <>
              <TypographyText className="mr-1 contents text-base text-gray-700 dark:text-gray-300">
                {t('careers:wantToJoinOurTeam')}{' '}
              </TypographyText>
              <TextButton
                size="lg"
                className="contents"
                onClick={handleScrollToJobList}
                label={`${t('careers:jobOpeningNoTag', {
                  number: tenant?.openJobsCount
                })}`}
              />
            </>
          ) : (
            <TypographyText className="text-base text-gray-700 dark:text-gray-300">
              {t('careers:currentlyDontHaveOpenJobs')}
            </TypographyText>
          )}
        </div>
        {careerPageSetting?.description?.[i18n.language] && (
          <div className="mb-8 tablet:mb-[140px]">
            <TypographyH2 className="mb-6 whitespace-nowrap text-center text-gray-900 dark:text-gray-300">
              {t('careers:aboutUs')}
            </TypographyH2>

            <LongContentDisplay
              limitLines={4}
              content={
                typeof careerPageSetting?.description === 'object'
                  ? careerPageSetting?.description[i18n.language]
                  : careerPageSetting?.description
              }
              className="mx-auto max-w-[700px] whitespace-pre-wrap text-center text-base text-gray-700"
              classNameButtonMore="flex items-center justify-center"
              textButtonProps={{ size: 'md' }}
            />
          </div>
        )}
        <TypographyH2 className="mb-10 hidden text-center text-gray-900 dark:text-gray-300 tablet:block">
          {t('careers:newOpportunities')}
        </TypographyH2>

        <TypographyH4 className="mb-4 text-gray-900 dark:text-gray-300 tablet:hidden">
          {t('careers:newOpportunities')}
        </TypographyH4>

        <CareerList
          tenantSlug={query.id}
          careerPageSetting={tenant?.careerSiteSettings}
          tenant={tenant}
        />
      </div>
    </Container>
  )
}

export default SimpleCareersView
