import { FC, useEffect, useState } from 'react'
import withTranslateProvider from 'src/hoc/with-translate-provider'
import CareerPreviewEditor from '~/components/Settings/Careers/Editor/CareerPreviewEditor'
import { SettingEditorFormType } from '~/components/Settings/Careers/Editor/CustomizeSettingTab'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryPublicCareersTemplateShow from '~/lib/features/careers/[id]/graphql/query-public-career-template'
import { TenantType } from '~/lib/features/careers/[id]/types'
import { mappingTemplateRespToSectionAndConfigData } from '~/lib/features/settings/careers/mapping/editor-mapping'
import { SectionsListType } from '~/lib/features/settings/careers/types/editor'

import en_career_editor from '../../../../public/locales/en/career-page-editor.json'
import ja_career_editor from '../../../../public/locales/ja/career-page-editor.json'
import vn_career_editor from '../../../../public/locales/vn/career-page-editor.json'

const CareerPageTemplateView: FC<{
  tenantSlug: string
  tenant?: TenantType
  showSwitchLanguages?: boolean
}> = ({ tenantSlug, tenant, showSwitchLanguages }) => {
  const [templateConfig, setTemplateConfig] = useState<SettingEditorFormType>()
  const [sections, setSections] = useState<SectionsListType>({
    lock: [],
    draggable: []
  })
  const { data: careerTemplate } = useQueryGraphQL({
    query: QueryPublicCareersTemplateShow,
    variables: {
      tenantSlug
    },
    shouldPause: !tenantSlug
  })

  useEffect(() => {
    if (careerTemplate) {
      const { templateConfig, sections } =
        mappingTemplateRespToSectionAndConfigData(
          careerTemplate.publicCareerTemplatesShow
        )

      setTemplateConfig(templateConfig)
      sections && setSections(sections)
    }
  }, [careerTemplate])

  return (
    <CareerPreviewEditor
      isEditorPage={false}
      showSwitchLanguages={showSwitchLanguages}
      sections={[...(sections?.lock || []), ...(sections?.draggable || [])]}
      templateConfig={templateConfig}
      tenant={tenant}
    />
  )
}

export default withTranslateProvider(
  CareerPageTemplateView,
  {
    en: { career_editor: en_career_editor },
    vn: { career_editor: vn_career_editor },
    ja: { career_editor: ja_career_editor }
  },
  true
)
