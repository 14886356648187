import { gql } from 'urql'
import { TemplateResponseType } from '~/lib/features/settings/careers/types/editor'

const QueryPublicCareersTemplateShow = gql<
  {
    publicCareerTemplatesShow: TemplateResponseType
  },
  {}
>`
  query ($tenantSlug: String!) {
    publicCareerTemplatesShow(tenantSlug: $tenantSlug) {
      id
      name
      references
      setting {
        images {
          file
          key
        }
      }
      sections {
        id
        name
        key
        index
        locked
        hidden
        hiddenChangable
        fields {
          id
          key
          name
          fieldKind
          values
          images {
            id
            file
          }
          sections {
            id
            name
            key
            index
            locked
            hidden
            hiddenChangable
            sectionableType
            fields {
              id
              key
              name
              fieldKind
              values
              images {
                id
                file
              }
            }
          }
        }
      }
    }
  }
`

export default QueryPublicCareersTemplateShow
