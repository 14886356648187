import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import SimpleCareersView from '~/components/Careers/[id]'
import CareerPageTemplateView from '~/components/Careers/[id]/CareerPageTemplateView'
import { LANG_ENG_OPTION, LANG_JA_OPTION } from '~/components/DropdownLanguage'
import LayoutFooterPowerBy from '~/components/Layout/LayoutFooterPowerBy'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { Skeleton } from '~/core/ui/Skeleton'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryTenant from '~/lib/features/careers/[id]/graphql/query-tenant'
import {
  CareerPageSettingType,
  TenantType
} from '~/lib/features/careers/[id]/types'
import useBoundStore from '~/lib/store'

const SimpleCareersContainer = ({
  careerPageSetting
}: {
  careerPageSetting: CareerPageSettingType
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const { query } = useRouter()
  const [tenant, setTenant] = useState<TenantType>()
  const { setToast } = useBoundStore()
  const fetchTenant = useCallback(() => {
    return clientGraphQL
      .query(QueryTenant, {
        tenantSlug: query.id
      })
      .toPromise()
      .then(
        (result: { error: any; data: { publicTenantsShow: TenantType } }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              setToast,
              error404ShouldForceToNotFoundPage: true
            })
          }

          const { publicTenantsShow } = result.data
          setTenant(publicTenantsShow)

          return publicTenantsShow
        }
      )
  }, [clientGraphQL, query.id])

  useEffect(() => {
    fetchTenant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!tenant && !tenant?.careerSiteSettings?.enablingCareerPageBuilder) {
      var link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
      }
      link.href = '/favicon.ico'
    }
  }, [tenant])
  return !tenant ? (
    <div className="">
      <Skeleton className="mx-auto my-[11px] h-10 w-full max-w-[1440px] rounded" />
      <Skeleton className="mx-auto my-[11px] h-[480px] w-full rounded" />
    </div>
  ) : tenant?.careerSiteSettings?.enablingCareerPageBuilder ? (
    <CareerPageTemplateView
      showSwitchLanguages={
        Object.values(careerPageSetting?.languages || {}).filter(
          (lang) => !!lang.enable
        ).length > 1
      }
      tenantSlug={String(query['id'])}
      tenant={tenant}
    />
  ) : (
    <LayoutFooterPowerBy
      showSwitchLanguages={
        Object.values(careerPageSetting?.languages || {}).filter(
          (lang) => !!lang.enable
        ).length > 1
      }
      languagesOptions={[LANG_ENG_OPTION, LANG_JA_OPTION]}>
      <SimpleCareersView
        careerPageSetting={careerPageSetting}
        tenant={tenant}
      />
    </LayoutFooterPowerBy>
  )
}

export default withQueryClientProvider(SimpleCareersContainer)
