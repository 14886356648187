import { gql } from 'urql'

const QueryTenant = gql`
  query ($tenantSlug: String!) {
    publicTenantsShow(tenantSlug: $tenantSlug) {
      name
      companyKind
      logoVariants
      description
      openJobsCount
      careerSiteSettings
      jobSettingRemoteStatusEnabling
    }
  }
`

export default QueryTenant
